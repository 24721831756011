import Questions from "../../components/Question/questions.component";
const QuestionPool = () => {
  return (
    <>
      <Questions />
    </>
  );
};

export default QuestionPool;
