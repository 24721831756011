import AllBatches from "../../components/batchCRUD/batch.component";

const Batch = () => {
    return(
        <>
        <AllBatches />
        </>
    );
}



export default Batch;