import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SearchField from "../../components/SearchField/searchField.component";
import CodeIcon from "../../assets/code.svg";
import ShortIcon from "../../assets/short.svg";
import MultipleChoiceIcon from "../../assets/multiple.svg";
import DeleteIcon from "../../assets/Delete.svg";
import InstanceIcon from "../../assets/Instance.svg";
import Isign from "../../assets/Exlamentory.svg";
import CrossSign from "../../assets/Cross.svg";
import { Link } from "react-router-dom";

import Button from "../../components/Button/button.component";
import FileDocument from "./FileDocument";
import { useNavigate } from "react-router-dom";

const AssessmentQuestions = ({ onSubmit, assessmentData }) => {
  const [questions, setQuestions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [droppedQuestions, setDroppedQuestions] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  // useEffect(() => {
  //   const token = localStorage.getItem("jwtToken");
  //   // If the token is not present, redirect to the login page
  //   if (!token) {
  //     navigate("/"); // Redirect to your login page route
  //   }
  // }, []);
  const handleClose = () => {
    setIsVisible(!isVisible);
  };
  const handleSearch = (value) => {
    setSearch(value);
  };
  const handleSaveChanges = () => {
    const combinedData = {
      assessmentHead: assessmentData,
      assessmentQuestions: droppedQuestions,
    };
    onSubmit(combinedData);
  };
  const handleSelect = (question) => {
    setDroppedQuestions(question);
  };

  const getTextColor = (typeTitle) => {
    switch (typeTitle) {
      case "Multiple Choice":
        return "#4CA154";
      case "Short Answer":
        return "#F2C14B";
      case "Code Snippet":
        return "#3662E3";
      default:
        return "inherit";
    }
  };

  const getBorderColor = (typeTitle) => {
    switch (typeTitle) {
      case "Multiple Choice":
        return "rgba(76, 161, 84, 0.3)";
      case "Short Answer":
        return "#F2C14B";
      case "Code Snippet":
        return "#3662E3";
      default:
        return "transparent";
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/Question?pageIndex=0&pageSize=100`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data.data);
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/Subject?pageIndex=0&pageSize=100`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSubjects(data.data);
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("droppedQuestions", JSON.stringify(droppedQuestions));
  }, [droppedQuestions]);

  const handleSubjectClick = (subjectId) => {
    if (selectedSubjects.includes(subjectId)) {
      setSelectedSubjects(selectedSubjects.filter((id) => id !== subjectId));
    } else {
      setSelectedSubjects([...selectedSubjects, subjectId]);
    }
  };

  const handleDrop = (question) => {
    const newDroppedQuestions = [...droppedQuestions, question];
    setDroppedQuestions(newDroppedQuestions);

    const remainingQuestions = questions.filter((q) => q.id !== question.id);
    setQuestions(remainingQuestions);
  };

  const QuestionCard = ({ question }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "CARD",
      item: { type: "CARD", question },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1, marginBottom: 10 }}
      >
        <div>
          <div
            className="tab"
            style={{
              color: getTextColor(question.questionTypeDetail.typeTitle),
              borderColor: getBorderColor(
                question.questionTypeDetail.typeTitle
              ),
            }}
          >
            <p
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                // marginLeft: 15,
              }}
            >
              {question.questionTypeDetail.typeTitle}
            </p>
            {question.questionTypeDetail.typeTitle === "Multiple Choice" && (
              <img src={MultipleChoiceIcon} alt="Multiple Choice" />
            )}
            {question.questionTypeDetail.typeTitle === "Short Answer" && (
              <img src={ShortIcon} alt="Short Answer" />
            )}
            {question.questionTypeDetail.typeTitle === "Code Snippet" && (
              <img src={CodeIcon} alt="Code Snippet" />
            )}
          </div>
          <p
            style={{
              color: "#121826",
              fontWeight: 400,
              fontSize: 14,
              fontFamily: "Lato",
              margin: 5,
            }}
          >
            {question.questionTitle}
          </p>
        </div>
      </div>
    );
  };

  const QuestionDropZone = ({ onSelect }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
      accept: "CARD",
      drop: (item) => handleDrop(item.question),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = canDrop && isOver;
    const handleMoveCard = (dragIndex, hoverIndex) => {
      const draggedCard = droppedQuestions[dragIndex];
      const updatedQuestions = [...droppedQuestions];
      updatedQuestions.splice(dragIndex, 1);
      updatedQuestions.splice(hoverIndex, 0, draggedCard);
      setDroppedQuestions(updatedQuestions);
    };

    const handleRemove = (index) => {
      const updatedDroppedQuestions = [...droppedQuestions];
      updatedDroppedQuestions.splice(index, 1);
      setDroppedQuestions(updatedDroppedQuestions);
    };

    return (
      <div ref={drop} className="QuestionDropZone">
        {droppedQuestions.map((droppedQuestion, index) => (
          <div key={index} className="drop-container">
            <div
              style={{
                position: "absolute",
                right: "19%",
                // marginTop: 5,
              }}
            >
              <button onClick={() => handleMoveCard(index, index + 1)}>
                <img src={InstanceIcon} alt="InstanceIcon" />
              </button>
              {/* <button onClick={() => handleMoveCard(index, index + 1)}>
                <img src={InstanceIcon} alt="InstanceIcon" />
              </button> */}
            </div>
            <div
              style={{
                position: "absolute",
                right: "18%",
                marginTop: 33,
              }}
            >
              <button onClick={() => handleRemove(index)}>
                <img src={DeleteIcon} alt="Delete" />
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <p className="subjectTitle-Tab">
                {droppedQuestion.subjectDetail.tittle}
              </p>
              <div
                className="typeDetail-Tab"
                style={{
                  border: `1px solid ${getBorderColor(
                    droppedQuestion.questionTypeDetail.typeTitle
                  )}`,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {droppedQuestion.questionTypeDetail.typeTitle ===
                  "Multiple Choice" && (
                  <img src={MultipleChoiceIcon} alt="Multiple Choice" />
                )}
                {droppedQuestion.questionTypeDetail.typeTitle ===
                  "Short Answer" && <img src={ShortIcon} alt="Short Answer" />}
                {droppedQuestion.questionTypeDetail.typeTitle ===
                  "Code Snippet" && <img src={CodeIcon} alt="Code Snippet" />}
                <p
                  style={{
                    color: getTextColor(
                      droppedQuestion.questionTypeDetail.typeTitle
                    ),
                  }}
                >
                  {droppedQuestion.questionTypeDetail.typeTitle}
                </p>
              </div>
            </div>
            <p
              style={{
                color: "#121826",
                fontWeight: 400,
                fontSize: 14,
                fontFamily: "Lato",
                padding: 8,
              }}
            >
              {droppedQuestion.questionTitle}
            </p>
            {droppedQuestion.fileDocumentDetail && (
              <FileDocument
                fileId={droppedQuestion.fileDocumentDetail.id}
                fileName={droppedQuestion.fileDocumentDetail.sourceFileName}
              />
            )}
            {/* <hr style={{ width: 356, border: "1px" }} /> */}
            {droppedQuestion.questionTypeDetail.typeTitle ===
              "Multiple Choice" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 10,
                }}
              >
                <label style={{ marginBottom: "8px" }}>
                  <input
                    type="radio"
                    name={`option${index}`}
                    value="Option A"
                    style={{ marginRight: 10 }}
                  />
                  Option A
                </label>
                <label style={{ marginBottom: "8px" }}>
                  <input
                    type="radio"
                    name={`option${index}`}
                    value="Option B"
                    style={{ marginRight: 10 }}
                  />
                  Option B
                </label>
                <label style={{ marginBottom: 8 }}>
                  <input
                    type="radio"
                    name={`option${index}`}
                    value="Option C"
                    style={{ marginRight: 10 }}
                  />
                  Option C
                </label>
              </div>
            )}
            {droppedQuestion.questionTypeDetail.typeTitle ===
              "Short Answer" && (
              <textarea
                style={{
                  width: "100%",
                  height: "100px",
                  padding: 8,
                  border: "none",
                  borderRadius: 8,
                  border: "1px solid #D2D5DA",
                  marginTop: 10,
                }}
                placeholder="Short Answer"
              />
            )}
            {droppedQuestion.questionTypeDetail.typeTitle ===
              "Code Snippet" && (
              <textarea
                style={{
                  width: "100%",
                  height: "100px",
                  padding: 8,
                  border: "1px solid #D2D5DA",
                  borderRadius: 8,
                  marginTop: 10,
                }}
                placeholder="Code Snippet"
              />
            )}
          </div>
        ))}

        {isActive ? (
          <p>Release to drop</p>
        ) : (
          <p
            style={{
              fontSize: 14,

              fontWeight: 400,
              fontFamily: "Lato",
              color: "#6C727F",
              width: 150,
            }}
          >
            Drag a question here to add it to the assessment.
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div style={{ position: "relative" }}>
          <div style={{ marginTop: 15, marginLeft: 15 }}>
            <SearchField onSearch={handleSearch} />
            <div className="subject-tabs">
              {subjects.map((subject) => (
                <div key={subject.id}>
                  <button
                    className={`subject-tab ${
                      selectedSubjects.includes(subject.id) ? "active" : ""
                    }`}
                    onClick={() => handleSubjectClick(subject.id)}
                  >
                    {subject.tittle}
                    {selectedSubjects.includes(subject.id) && (
                      <span className="cross-icon">&#10005;</span>
                    )}
                  </button>
                </div>
              ))}
            </div>

            {isVisible ? (
              <div className="questionEdit-container">
                <div style={{ display: "flex", marginLeft: 10 }}>
                  <img src={Isign} alt="Sign" />
                  <h6
                    style={{
                      color: "#121826",
                      fontWeight: 700,
                      fontSize: 14,
                      fontFamily: "Lato",
                      textAlign: "center",
                      marginLeft: 12,
                    }}
                  >
                    Questions are not editable
                  </h6>
                  <button
                    onClick={handleClose}
                    style={{
                      marginLeft: "30%",
                    }}
                  >
                    <img src={CrossSign} alt="CrossSign" />
                  </button>
                </div>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#6C727F",
                    marginLeft: 33,
                  }}
                >
                  Please visit{" "}
                  <Link>
                    <span
                      style={{
                        color: "#4E80EE",
                        fontWeight: 600,
                        fontSize: 14,
                        fontFamily: "Lato",
                        textDecoration: "underline",
                      }}
                    >
                      questions
                    </span>{" "}
                  </Link>
                  to edit them.
                </p>
              </div>
            ) : null}
            <div
              // className="questions-list"
              style={{
                overflowY: "auto",
                height: 1237,
                width: 380,
              }}
            >
              {questions
                .filter((question) =>
                  selectedSubjects.length === 0
                    ? true
                    : selectedSubjects.includes(question.subjectId)
                )
                .map((question) => (
                  <div key={question.id}>
                    <div className="container">
                      <QuestionCard question={question} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <QuestionDropZone onSelect={handleSelect} />
        </div>
      </DndProvider>
      <div
        style={{
          width: 397,
          height: 92,
          backgroundColor: "#FFFFFF",
          border: "1px solid #D2D5DA",
          display: "flex",
          justifyContent: "flex-end",
          padding: 25,
        }}
      >
        <Button
          button_type="primary"
          button_size="medium"
          text="Save Changes"
          icon="none"
          src=""
          onclick={handleSaveChanges}
        />
      </div>
    </>
  );
};

export default AssessmentQuestions;
