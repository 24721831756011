import FormTitle from "../FormTitle/formTitle.component";
import Label from "../Label/label.component";
import Button from "../Button/button.component";
import CheckIconBig from "../../assets/checkIconBig.svg";
import {useState , useEffect} from 'react';

const EditAssessment = ({displayModalValue}) =>
{

    const [assessmentCategories, setAssessmentCategories] = useState([]);

    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    
    //     fetch(
    //       `${process.env.REACT_APP_BASE_URL}/api/AssessmentCategory?pageIndex=0&pageSize=100`,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     )
    //       .then((response) => response.json())
    //       .then((data) => {
    //         setAssessmentCategories(data.data);
    //         setSelectedCategory(data[0]?.id || "");
    //       })
    //       .catch((error) =>
    //         console.error("Error fetching assessment categories:", error)
    //       );
    //   }, []);



    const onClose = (value) => {
        //value from child to parent
        console.log("boolean value from form title is :", value);
        displayModalValue(value);
    };

    const handleCancel = () => {
        displayModalValue(false);
    }

    return(
      <>
        {/*  main div starts here */}
        <div className="edit-form-container-styles">
        {/*   TITLE OF FORM */}
        {/*  comp for form title */}
        <FormTitle title="Edit Assessment" formClosed={onClose} />

        {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
        {/*  FORM */}

        {/* form-styles and input-div-styles are custom tailwindcss classes in index.css */}
        <form className="edit-form-styles">
          <div className="input-div">
            <Label label="Title" />
            <input
                type="text"
                placeholder="Title"
                className="input-styles"
                name="title"
                // value={formData.title}
                // onChange={handleInputChange}
            />
          </div>
{/* 
          <div className="input-div-styles">
            <Label label="Assessment Category" />
            <select
                className="input-styles"
                value={selectedCategory}
                onChange={handleCategoryChange}
                name="assessmentCategoryId"
            >
               {assessmentCategories.map((category) => (
                <option key={category.id} value={category.id}>
                    {category.title}
                </option>
                ))}
            </select>
          </div> */}

{/* 
          <div className="flex gap-x-4">
            <div className="input-div-styles w-[232px]">
                <Label label="Reg Date" />
                <input
                    type="date"
                    className="input-styles w-[232px]"
                    name="registrationDate"
                    value={formData.registrationDate}
                    onChange={handleInputChange}
                />
            </div>
            <div className="input-div-styles w-[232px]">
                <Label label="Due Date" />
                <input
                    type="date"
                    className="input-styles w-[232px]"
                    name="dueDate"
                    value={formData.dueDate}
                    onChange={handleInputChange}
                />
            </div>
          </div> */}

          {/* <div className="input-div-styles w-[480px] h-[120px]">
            <Label label="Note" />
            <textarea
                className="textarea-styles"
                placeholder="Write your notes here"
                rows="5"
                name="note"
               
            />
            Character counter
            <div className="flex justify-between mt-2">
                <span>{noteLength}/250</span>
            </div>
          </div> */}

          {/* <div>
            <span style={{ fontSize: 12, fontWeight: 600, color: "#6C727F" }}>
                Status
            </span>
            <div
                style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                }}
            >
                <Toggle
                name="isPublished"
                type="checkbox"
                checked={formData.isPublished}
                onChange={handleToggleChange}
                />
                <span
                style={{
                    color: "#121826",
                    fontSize: 14,
                    fontWeight: 400,
                    paddingLeft: 10,
                }}
                >
                Published
                </span>
            </div>
          </div> */}

        </form>
        {/* form ends here */}
        {/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

        {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
        {/*  BUTTONS DIV */}

        {/*  div for cancel and add ques button */}
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {submissionMessage && (
            <Alert
              alert_type={"success"}
              title={"Success"}
              description={submissionMessage}
            />
          )}
          {error && (
            <Alert
              alert_type={"Invalid"}
              title={"Invalid"}
              description={error}
            />
          )}
        </div> */}
        
        <div className="w-[520px] h-[84px] p-5 flex justify-end">
          <div className="flex gap-x-4">
            {/*   Button comp */}
            <Button
              button_type="tertiary"
              button_size="medium"
              text="Cancel"
              onclick={handleCancel}
              icon="none"
              src=""
            />
            <Button
              button_type="primary"
              button_size="medium"
              text="Save Changes"
            //   onclick={handleAddQues}
              icon="left"
              src={CheckIconBig}
            />
          </div>
        </div>

          {/* {editSuccessMsg && navigate("/questions")} */}
      </div>
    
      {/*   main div ends */}
      {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
    </>
    );
}


export default EditAssessment;