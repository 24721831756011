import SearchField from "../../components/SearchField/searchField.component";
import AddAssessment from "../../components/Assessments/addAssessment.component";
import Chips from "../../components/Chips/chips.component";
import Button from "../../components/Button/button.component";
import FilterIcon from "../../assets/filterIcon.svg";
import PlusBig from "../../assets/PlusBig.svg";
import seenIcon from "../../assets/seenIcon.svg";
import ThreeDots from "../../assets/threeDots.svg";
import RedDeleteIcon from "../../assets/redDeleteIcon.svg";
import EditIcon from "../../assets/editIcon.svg";
import Modal from 'react-modal';

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate , useParams } from "react-router-dom";
import EditAssessment from "../../components/Assessments/editAssessment.component";

const Assessment = () => {
  const [assessmentData, setAssessmentData] = useState([]);
  console.log("ASSESSMENT DATA ::::::::::::::::::" , assessmentData);
  const [delSuccess, setDelSuccess] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [displayEditAssessmentModal , setDisplayEditAssessmentModal] = useState(false);


  const navigate = useNavigate();
  const {id} = useParams();
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   // If the token is not present, redirect to the login page
  //   if (!token) {
  //     navigate("/"); // Redirect to your login page route
  //   }
  // }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/AssessmentHead?pageIndex=0&pageSize=100`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setAssessmentData(data.data);
        // console.log("AssessmentHead Data" + data.data);
      })
      .catch((error) =>
        console.error("Error fetching assessment data:", error.message)
      );
  }, []);


  const handleDelete = (e, id_) => {

    console.log("handle delete assessment :::::::::::::::::::" );
    const token = localStorage.getItem("token");

    e.stopPropagation();
    setDeleteBtn(false);
    // console.log("ques Data : ", quesData);
    console.log(" id from del func is :", typeof id_);
    console.log("use params id  is :", { id });

    fetch(`${process.env.REACT_APP_BASE_URL}/api/AssessmentHead/${id_}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      /* No need to pass quesData in the body for deletion */
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // Filter out the deleted question from quesData and update the state
          const updatedData = assessmentData.filter((item) => item.id !== id_.toString());
          setAssessmentData(updatedData);
          setDelSuccess(true); // Set delSuccess to true if deletion is successful
        }
      })
      .catch((error) => {
        console.error("Error deleting assessment:", error);
      });
  };



  const handleEditForm = () => {
    setDisplayEditAssessmentModal(true);
  }


  const handleCloseModal = (val) => {
    setDisplayEditAssessmentModal(val);
  }


  const customStyles = {
    overlay: {
      background: "rgb(0, 0, 0, 0.4)",
    },
    content: {
      width: "fit-content",
      /* height:'fit-content', */
      marginLeft: "auto",
      marginRight: "auto",
      background: "transparent",
      border: "none",
      padding: "0px 1px 0px 0px",
    },
  };

  return (
    <>
      {/* ASSESSMENT CONTAINER */}
      <div className="flex flex-col gap-y-4 py-10">
        <h2 className="text-2xl ml-4">Assessments</h2>

        {/*  section 1 : SEARCH FIELD , FILTER AND BUTTON */}
        <div className="flex justify-between">
          {/* search field comp */}
          <SearchField />
          {/* filter and add assessment button */}
          <div className="flex gap-x-4">
            <img
              src={FilterIcon} /*  onClick={() => { setFilter(!filter) }} */
            />
            <Link to="/addNewAssessment">
              <Button
                button_type="primary"
                button_size="medium"
                text="Add Assessment"
                icon="left"
                src={PlusBig}
              />
            </Link>
          </div>
        </div>

        {/* ---------------section 1 ends ----------------- */}



        {/* section 2 : ASSESSMENT CARDS*/}
        <div className="grid grid-cols-3 gap-3 ml-4">
          {assessmentData.map((assessment) => (
            <Link to={`/assessment/editAssessment/${assessment.id}`} key={assessment.id}>
              {/* MAIN CONTAINER */}
              <div className="border border-secondary rounded-lg px-3 pt-3 pb-6 w-[340px] h-[152px] flex flex-col gap-y-2" onClick={handleEditForm}>
                {/* SUB DIV ---------- 1 */}
                <div className="flex justify-between ">
                  <div className="flex gap-x-2">
                    {/* CHIPS */}
                    <Chips
                      text={assessment.assessmentCategoryDetail.title}
                      size="large"
                    />
                    {/* EYE ICON */}
                    <div className="self-center">
                      <img src={seenIcon} />
                    </div>
                  </div>
                  {/* THREE DOTS */}
                  <div className="flex flex-col">

                    {/* div that holds three dots and takes to actions routes */}
                    <div onClick={(e) => {e.stopPropagation(); e.preventDefault();setDeleteBtn(!deleteBtn);}}>
                      {/* three dots image to perfom actions (delete) */}
                      <Link to={`/assessment/actions/${assessment.id}`}>
                        <img src={ThreeDots} className="p-1" />
                      </Link>
                    </div>

                    {/* this is delete button div , initially its hidden when clicked on this div, delete option will be displayed */}
                      <div
                        className="border py-3 px-5 rounded-lg bg-disabled border-secondary shadow w-[220px] min-h-11 h-[fit-content]"
                        style={{
                          display:id == assessment.id && deleteBtn ? "block" : "none",
                          position: "absolute",
                          zIndex: "0",
                          marginLeft: "-196px",
                          marginTop: "10px",
                        }}
                      >
                        {/* this div contains a trash icon and a delete text */}
                        <div className="flex flex-col gap-y-3">
                         <Link to={`/assessment/deleteAssessment/${assessment.id}`} onClick={(e) => {handleDelete(e, assessment.id);}}>
                           <div className="flex gap-x-3">
                             <img src={RedDeleteIcon} />
                             <p className="w-[fit-content] text-sm text-red-600 font-semibold">Delete</p>
                           </div>
                          </Link>
                          <Link to={`/assessment/editAssessment/${assessment.id}`} onClick={(e) => {handleDelete(e, assessment.id);}}>
                           <div className="flex gap-x-3">
                             <img src={EditIcon} />
                             <p className="w-[fit-content] text-sm font-semibold">Edit</p>
                           </div>
                          </Link>
                          <Link to="/assessment/assignAssessment" onClick={(e) => {handleDelete(e, assessment.id);}}>
                           <div className="flex gap-x-3">
                             <img src={EditIcon} />
                             <p className="w-[fit-content] text-sm font-semibold">Assign Assessment</p>
                           </div>
                           </Link>
                        </div>

                      </div>
                   

                    {delSuccess && navigate("/assessment")}
                  </div>
                  {/* THREE DOTS DIV ENDS HERE */}

                  {/* <Link to="/assessment/assignAssessment">
                    <div className="self-center">
                      <img src={ThreeDots} />
                    </div>
                  </Link> */}
                </div>
                 {/* SUB DIV ---------- 1 ENDS HERE*/}

                {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
                 
                {/* SUB DIV ---------- 2 : ASSESSMENT TITLE , REGISTRATION DATE , DUE DATE*/}
                <div>
                  {/* ASSESSMENT TITLE  */}
                  <div>
                    <h5 style={{color: "#121826",fontSize: 16,fontWeight: 700,fontFamily: "Lato" }}>{assessment.title}</h5>
                  </div>
                  {/* REGISTRATION DATE  */}
                  <div>
                    <p style={{ color: "#6C727F", fontSize: 14, fontWeight: 600 }}>Registration Date:{" "}
                      <span style={{color: "#121826",fontSize: 14,fontWeight: 400,}}>
                        {assessment.registrationDate.substring(0, 10)}
                      </span>
                    </p>
                  </div>
                  {/* DUE DATE */}
                  <div>
                    <p style={{ color: "#6C727F", fontSize: 14, fontWeight: 600 }}>
                      Due Date:{" "}
                      <span style={{color: "#121826",fontSize: 14,fontWeight: 400,}}>
                        {assessment.dueDate.substring(0, 10)}
                      </span>
                    </p>
                  </div>
                </div>

                {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

              </div>
              {/* MAIN CONTAINER ENDS HERE */}
            </Link>
          ))}
        </div>

        {/* --------------section 2 ends here------------------- */}

        
        {/* when clicked on question card , Edit Popup will be opened inside modal */}
        <Modal
          isOpen={displayEditAssessmentModal}
          style={customStyles}
          height="400px"
          width="fitContent"
          ariaHideApp={false}
        >
          <EditAssessment
            displayModalValue={handleCloseModal}
            // ariaHideApp={false}
            // id={id}
            // refreshStudents={fetchQuestionsData}
          />
        </Modal>
      </div>
      {/* ASSESSMENT CONTAINER ENDS HERE */}
    </>
  );
};

export default Assessment;
